.container {

  //  查看版本号
  .login_contel_itemIMG {
    // width: calc( 208px * 0.8 );
    // height: calc( 45px * 0.8 );
    width: 155px;
  }

  .banner_textfoolt_image {
    // margin-top: 40px;
    // width: calc( 593px * 0.8 );
    // height: calc( 45px * 0.8 );
  }

  .version {
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100px;
    height: 50px;
  }

  .arco-input-inner-wrapper {
    width: 386px !important;
    height: 50px !important;
    border-radius: 8px !important;
    font-weight: 400;
    border: 1px solid #eeeeee;
    background: #ffffff;

    &::focus {
      border-color: #417aff !important;
      background-color: #cccc;
    }

    .arco-input {
      font-size: 16px;
    }

    .arco-input::-webkit-input-placeholder {
      padding-left: 5px;
    }
  }

  .login-form-itemhover {

    margin-left: 5px;
  }

  .arco-form-message {
    width: 386px;
  }

  .arco-form-item-error {
    margin-bottom: 0 !important;
  }

  .arco-form-item-wrapper {
    width: 100% !important;
  }

  .arco-form-item {
    margin-bottom: 27px;

    .arco-form-message {
      margin-top: 5px;
      margin-bottom: 5px;
      min-height: 17px;
      height: 17px !important;
      line-height: 17px;

      &>div {
        height: 17px !important;
        line-height: 17px;
      }
    }

    .arco-form-extra {
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 10px;
      height: 17px !important;
      line-height: 17px;
    }
  }

  .form-cottopre {
    margin-top: 20px;
    position: relative;
  }

  .form-cottop {
    margin-top: 20px;
    position: relative;
  }

  // .arco-input-group-wrapper {
  //   width: 386px;
  //   height: 50px;

  //   border-radius: 8px !important;
  //   font-weight: 400;
  //   font-size: 16px;
  // }
  .arco-input-inner-wrapper:focus,
  .arco-input-inner-wrapper.arco-input-inner-wrapper-focus {
    border-color: #417aff !important;
    background-color: #fff !important;
  }

  .arco-form-item-status-error .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled) {
    border-color: #417aff;
    background-color: #fff;
  }

  .arco-form-item-status-error .arco-input-inner-wrapper:not(.arco-input-inner-wrapper-disabled):hover {
    border-color: #417aff;
    background-color: #fff;
  }

  #opa {
    font-weight: 400;
    font-size: 16px;
    color: #165dff;
    line-height: 22px;
    text-align: left;
    border: none;
  }

  #login-actions {
    display: flex;
    justify-content: space-between;
  }

  // .form-contorinput {
  //   width: 386px;
  //   height: 50px;

  //   border-radius: 8px !important;
  //   font-weight: 400;
  //   font-size: 16px;
  //   background-color: #ffffff;
  // }
  #usename_input {
    width: 386px;
    height: 50px;
    border-radius: 8px !important;
    font-weight: 400;
    font-size: 14px;
    // background-color: #FFFFFF;
    border: 1px solid #eeeeee;
  }

  .Tableactive {
    width: 80px;
    height: 0;
    border: 1px solid #417aff;
    margin-top: 7px;
    border-radius: 2px;
  }

  #inputyzmi {
    height: 20px;
    width: 20px;
    border-left: 1px solid #aea9a9;
  }

  .inputyzmbody {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 96px;
  }

  #regist {
    margin-top: 30px;
  }

  .psswordonecolor {
    width: 30px;
    height: 9px;
    margin-left: 10px;
    margin-top: 6px;
  }

  .passQD {
    position: absolute;
    width: 386px;
    top: 292px;
    display: flex;
    height: 22px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
  }

  .passQDleft {
    display: flex;
    font-size: 14px;
    line-height: 17px;
    height: 17px;
    font-weight: 400;
  }

  .lpassQDright {
    height: 20px;
    font-size: 14px;
    line-height: 17px;
    height: 17px;
    font-weight: 400;
    text-align: left;
    margin-right: 22px;
  }

  .passQDleftdiv {
    display: flex;
  }

  .passQDess {
    position: absolute;
    top: 292px;
    font-size: 12px;
    line-height: 17px;
    height: 17px;
    font-weight: 400;
    color: #666666;
    margin-left: 15px;
  }

  .passQDL {
    width: 386px;
    display: flex;
    line-height: 17px;
    height: 17px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: #ffffff;
  }

  .passQDessop {
    position: absolute;
    top: 292px;
    font-size: 12px;
    height: 22px;
    font-weight: 400;
    color: #666666;
    margin-left: 15px;
    line-height: 22px;
  }

  .arco-tabs-header-nav::before {
    background-color: #fff;
  }

  .arco-tabs-header-title-text {
    font-size: 16px;
    font-weight: 600;
  }

  .ardioactive {
    width: 15px;
    height: 15px;
    margin-top: 2px;
    margin-right: 7px;
    margin-left: 5px;
  }

  .ardioactiveer {
    width: 13px;
    height: 13px;
    margin-top: 2px;
    margin-right: 7px;
    margin-left: 5px;
    border: 1px solid #e5e6eb;
    border-radius: 50%;
  }

  .radiobody {
    display: flex;
    line-height: 19px;
    font-size: 14px;
    font-weight: 400;
  }

  .forminputerror {
    position: absolute;
    font-weight: 400;
    font-size: 12px !important;
    color: #9e9e9e;
    height: 20px;
    line-height: 20px;
    text-align: left;
    top: 18%;
    margin-left: 10px;
    margin-top: 5px;
  }

  .arco-form-message {
    margin-left: 10px;
  }
}

.retunrloginbody {
  width: 100%;
  height: 100%;
}

.returnloginbodymain {
  margin: 0 auto;
}

// 忘记密码页 跳转
.returnloginbodymain {
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .retunlogintopheader {
    display: flex;
    align-items: center;
    margin: 0 auto;

    p {
      margin-left: 10px;
      font-weight: 400;
      font-size: 18px;
      color: #333333;
      line-height: 25px;
      text-align: left;
      font-style: normal;
    }
  }

  .returnloginbodyjopj {
    margin: 0 auto;
    margin-top: 10px;
    color: #999999;
    font-size: 15px;
  }

  .subminglogin {
    width: 170px;
    height: 54px;
    background: #165dff;
    border-radius: 8px 8px 8px 8px;
    margin-left: 121px;
    margin-top: 50px;
  }
}

.container {
  display: flex;
  height: 100vh;
  min-height: 950px;

  .content {
    flex: 1;
    position: relative;
    padding-bottom: 40px;
    max-width: 55%;
    min-width: 600px;
    height: 100vh;
    min-height: 740px;

    .inner {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .icp-info {
      font-weight: 400;
      font-size: 12px;
      color: #9E9E9E;
      padding: 0 18px;
      margin-top: 15px;
      box-sizing: border-box;
      text-align: center;
      padding-bottom: 35px;

      &>p:last-child {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 3px;
      }
    }
  }

  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.logo {
  position: fixed;
  top: 24px;
  left: 22px;
  display: inline-flex;
  align-items: center;
  z-index: 1;

  .text {
    margin-left: 4px;
    margin-right: 4px;
    font-size: 20px;
    color: var(--color-fill-1);
  }
}

.banner {
  width: 50%;
  height: 100%;
  min-width: 675px;
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    height: 100%;
    flex: 1;



    .carousel {
      height: 100%;

      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        // min-height: 954px;
        // background: linear-gradient(158deg, #3482ff, #ab50ff);
        background: linear-gradient(136deg, #3d27b0 0%, #5266e3 100%);
        // .banner-image {
        //   width: 619px;
        //   height:777px;
        // }
        // .textfoolt {
        //   width: 593px;
        //   height: 45px;
        //   margin-top: 40px;
        // }
      }

      .title {
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        color: var(--color-fill-1);
      }

      .sub-title {
        margin-top: 8px;
        font-size: 14px;
        line-height: 22px;
        color: var(--color-text-3);
      }

      .image {
        margin-top: 30px;
        width: 320px;
      }
    }
  }
}

.arco-input-clear-wrapper .arco-input-inner-wrapper-default .form-contorinput .arco-input-inner-wrapper {
  width: 480px;
  height: 620px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px #d2e5f7;
  border-radius: 8px 8px 8px 8px;
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hoverinput {
  border: 1px solid #417aff;
  border-radius: 8px;
}

.login-form-wrapper {
  width: 386px;
  height: 500px;
  // margin-top: 60px;
  margin: 0 auto;
}

.login-form-active {
  width: 86px;
  height: 28px;
  font-weight: 600;
  font-size: 20px;
  color: #333333;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin: 0 61px 0 0;
}

.login-formtomsgt {
  display: flex;
  text-align: center;

  .login-form-active {
    width: 86px;
    height: 28px;
    font-weight: 600;
    font-size: 20px;
    color: #417aff;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 0 61px 0 0;
  }

  .login-form-sub-title {
    width: 86px;
    height: 28px;
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    margin: 0 61px 0 0;
  }

  .login-form-itemhover:hover {
    cursor: pointer;
  }

  .hoverinput {
    border: 1px solid #417aff;
    border-radius: 8px;
  }

  .error-msg {
    height: 32px;
    line-height: 32px;
    color: rgb(var(--red-6));
  }

  &-register-btn {
    color: var(--color-text-3) !important;
  }

  .login-formitmyz {
    &-SJ {
      height: 54px;
      background: #ffffff;
      border-radius: 8px 8px 8px 8px;
      border: 1px solid #417aff;
    }
  }
}

.bttomactivehov {
  width: 100%;
  position: absolute;

  // bottom: -50px;
  .bottmbodyheader {
    width: 90%;
    margin: 0 auto;
  }
}

.toppoab {
  position: absolute;
  top: -50px;

  .Fooass_wordtext {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-top: 82px;
  }
}

.styleInputbutton {
  width: 386px;
  height: 54px;
  border-radius: 8px;
}

// .form-contorinput{
//   width: 480px;
//   height: 620px;
//   background: #FFFFFF;
//   box-shadow: 0px 2px 6px 0px #D2E5F7;
//   border-radius: 8px 8px 8px 8px;
// }
