.project-card-wrapper {
  width: 180px;
  height: 230px;
  background: #f5f8ff;
  border-radius: 4px 4px 4px 4px;
  padding-top: 28px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
  margin-right: 13px;
  margin-bottom: 13px;
  &:hover {
    .more-opearte {
      display: inline-block;
    }
  }
  .more-opearte {
    display: none;
    position: absolute;
    bottom: 41px;
    right: 13px;
  }
  &.opeater-box {
    &:hover .checkbox-wrapper {
      display: block;
    }
  }
  .show {
    display: inline-block !important;
  }
  .checkbox-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
  }
  .tag-wrapper {
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 10;
    transform: scale(0.7);
  }
  .project-name {
    margin-top: 3px;
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    width: 126px;
    font-style: normal;
    text-transform: none;
    padding-left: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: center;
  }
  .project-update-time {
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #999999;
    line-height: 22px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    padding-left: 18px;
  }

  img {
    width: 120px;
    height: 120px;
  }
  .life-wrapper {
    z-index: 10;
    width: 30px;
    background: #707070;
    border-radius: 2px 2px 2px 2px;
    font-weight: 500;
    font-size: 10px;
    color: #ffffff;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
}

