.layout-collapse-demo {
  width: 100%;
  height: 100%;
  color: #3d3d3d;
  display: flex;
  flex-direction: column;
  .arco-layout {
    flex-direction: row;
    height: calc(100vh - 83px);
  }
  background: linear-gradient(180deg, #e3ebff 0%, #efe2ff 97%);
}
.content_box {
  // height: calc(100vh - 66px);
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  margin-right: 16px;
  position: relative;
  border-radius: 12px 12px 0 0;
}
.content_box::-webkit-scrollbar {
  display: none;
}

.content_box {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

