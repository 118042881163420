//
.custom-video-container {
  /* 自定义容器样式 */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
}

.custom-video {
  /* 自定义视频样式 */
  width: 100%;
  height: auto;
  border-radius: 8px;
  /* 圆角边框 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* 添加阴影 */
}

.custom-video-controls button:hover {
  background-color: #0056b3;
}

// video::-webkit-media-controls
.custom-video-container {
  position: relative;

  // width: 300px;
  // height: 480px;
  .video-wrapper {
    background-color: #ffffff;
    z-index: 1;
    height: 100%;

    &.default {
      width: 100%;
    }

    &.full-screen {
      // position: relative;
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: relative;
    z-index: 1 !important;
    cursor: pointer;
    background-color: #333333;
    // object-fit: fill;
  }

  .custom-video-controls {
    /* 自定义控制按钮样式 */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .custom-video-controls button {
    /* 自定义按钮样式 */
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }

  .video-icon-Playing {
    position: absolute;
    top: 45%;
    z-index: 2;
    top: calc(100% / 2 - 25px);
    left: calc(100% / 2 - 25px);
  }

  .custom-video-controls-S {
    position: fixed;
    bottom: 0;
    // bottom: 0;
    width: 98%;
    height: 75px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    padding: 0 1%;
  }

  .custom-video-controls-C {
    position: absolute;
    bottom: 0;
    width: 94%;
    height: 75px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    padding: 0 3%;
  }

  .video-controlsprogress-slide {
    width: 94%;
    background: rgba(134, 144, 156, 0.54);
    border-radius: 8px 8px 8px 8px;
    height: 33px;
    padding: 0 3%;
  }

  .video-progress-slider {
    width: 100%;

    height: 100%;
    display: flex;
    align-items: center;

    .arco-slider {
      width: 100% !important;
      // padding: 0 3%;
      // align-items: center;
      // line-height: 33px;
      height: 12px;
    }

    .arco-slider-road::before {
      height: 5px;
    }

    .arco-slider-button {
      z-index: 3;
      height: 14px;
      width: 14px;
    }

    .arco-slider-button::after {
      border: none;
    }

    .arco-slider-bar {
      height: 5px;
      background-color: rgb(156, 191, 255);
    }
  }

  .video-contel-Fluss {
    width: 97%;
    height: 35px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2%;

    .slider-volume-body {
      display: flex;
      align-items: center;
    }

    .video-Fluess-Time {
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      line-height: 17px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .slider-volume {
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      margin-right: 12px;

      .arco-slider-road.arco-slider-road-vertical {
        min-height: 40px;
      }
    }

    .Fluess-Icon-fluess {
      display: flex;
      align-items: center;
    }
  }

  .video-silder-wrapper {
    display: none;
    background-color: rgba(21, 23, 27, 0.68);
    position: absolute;
    z-index: 3;
    top: -68px;
    left: -4px;
    text-align: center;

    // &::after{
    //   content: "";
    //   width: 0;
    //   height: 0;
    //   border-style: solid;
    //   border-color: black transparent transparent transparent;
    //   border-width: 4px;
    //   position: absolute;
    //   top: 70px;
    //   left: 7px;
    // }
    .volume-container {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }

  .video-slider-hover {
    position: relative;
  }

  .video-slider-hover:hover {
    .video-silder-wrapper {
      display: block;
    }
  }

  .video-slider-hover-contel {
    font-size: 10px;
    color: white;
  }
}

.propsem-video-prost {
  width: 48px;
  height: 28px;
  z-index: 1;
  top: 7px;
  display: flex;
  flex-direction: column;

  .box-contel {
    width: 100%;
    display: flex;

    .boxa {
      width: 3px;
      height: 17px;
      background-color: #165dff;
    }

    .boxb {
      flex: 1;
      height: 17px;
      background: rgba(22, 93, 255, 0.68);
    }

    .boxc {
      width: 3px;
      height: 17px;
      background-color: #165dff;
    }
  }

  .box-footer {
    display: flex;

    .box-footer-a {
      width: 50%;
      display: flex;
      flex-direction: column;
    }

    .box-footer-left {
      width: 3px;
      height: 7px;
      background-color: #165dff;
    }

    .box-footer-left-bottom {
      width: 5px;
      height: 2px;
      margin-left: -1px;
      background-color: #165dff;
    }

    .box-footer-b {
      width: 50%;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .box-footer-right {
      width: 3px;
      height: 7px;
      background-color: #165dff;
    }

    .box-footer-right-bottoma {
      width: 5px;
      height: 2px;
      margin-right: -1px;
      background-color: #165dff;
    }
  }
}

.video-imgsrc-hover {
  opacity: 1;
  transition: opacity 0.5s linear;
}

.video-icon-Playing {
  img {
    opacity: 1;
    transition: opacity 0.5s linear;
  }
}

.video-iconimgsrc-hover {
  opacity: 0;
  transition: opacity 0.5s linear;
}

.video-iconimgsrc-hover-display {
  display: none;
}

