li {
  // margin-left: 16.5px;
  // list-style-type: disc;
}

.arco-modal-header {
  background: linear-gradient(135deg,
      rgba(65, 122, 255, 0.3) 0%,
      rgba(171, 80, 255, 0.084) 100%);
}

.creation-content {
  border-radius: 12px;
  position: relative;
  margin-top: 18px;
  width: 100%;
  height: calc(100% - 16px);
  margin-bottom: 16px;
  box-sizing: border-box;

  .creation-title {
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #333333;
  }

  display: flex;

  .suspend-ai {
    position: absolute;
    right: 0;
    bottom: 25%;
    cursor: pointer;
    z-index: 3;
  }

  .content-left {
    min-width: calc(100vw - 781px);
    background: #ffffff;
    border-radius: 12px;
    padding: 20px;
    overflow-x: hidden;
    width: 100%;

    .save {
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      margin-left: 20px;
    }

    .creation-tool {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .creation-title {
        margin-left: 16px;
        margin-right: 22px;
      }

      .creation-btn-box {
        display: flex;

        .arco-avatar-text {
          width: 100%;
          height: 100%;
        }

        .import-script-btn,
        .add-column-btn {
          height: 38px;
          border-radius: 2px;
          border: 1px solid #165dff;
          background-color: white;
          font-weight: 400;
          font-size: 14px;
          color: #165dff;
          display: flex;
          align-items: center;
          margin-right: 12px;
        }

        .save-btn {
          display: flex;
          align-items: center;
          height: 38px;
          background: linear-gradient(135deg, #165dff 0%, #cb98fa 100%);
          border-radius: 2px;
          border: none;
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
        }

        .creation-avatarGroup {
          display: flex;
          align-items: center;
          margin-right: 15px;

          .avatar-num {
            color: #adacac;
            transform: none;
            font-size: 16px;
          }
        }

        .arco-avatar {
          width: 35px;
          height: 35px;
        }

        .arco-avatar-text {
          transform: translateX(-50%) !important;
        }

        .user-image {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
      }
    }

    .creation-name {
      display: flex;
      align-items: center;
      height: 24px;

      .arco-input {
        padding: 0;
        background-color: white;
        font-size: 20px;
        font-weight: 500;
        border: none;
        flex: none;
      }

      .arco-input::placeholder {
        color: #999999;
      }
    }

    .creation-name-border {
      height: 19px;
      border-bottom: 1px solid #eeeeee;
    }

    .creationScript-table {
      min-width: calc(100vw - 781px);
      max-height: calc(100vh - 262px);
      margin-top: 34px;
      overflow: auto;
    }

    .table_bottom-hr {
      width: 100%;
      height: 38px;
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .add-grouping {
        height: 38px;
        border-radius: 2px;
        border: 1px solid #165dff;
        background-color: white;
        font-weight: 400;
        font-size: 14px;
        color: #165dff;
        display: flex;
        align-items: center;
      }

      &>div:nth-child(1) {
        width: 100%;
        height: 1px;
        background-color: #cccccc;
      }

      &>div:nth-child(3) {
        height: 1px;
        width: 100%;
        background-color: #cccccc;
      }
    }

    // }
    .table {
      // width: 100%;

      display: table;
      border-spacing: 0;
      /* 移除单元格之间的间距 */
      empty-cells: show;
      /* 显示空单元格的边框 */
      border-bottom: 1px solid #cccccc;
      border-right: 1px solid transparent;

      // overflow: hidden; /* 隐藏超出表格的部分 */
      thead {
        tr {
          th {
            position: relative !important;
            height: 48px;
            background: white;
            font-size: 14px;

            & div:first-child {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 12px;
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              z-index: 999;
              box-sizing: border-box;

              .th-message-info {
                font-weight: 400;
                font-size: 12px;
                color: #999999;
              }
            }
          }
        }
      }

      tbody {
        tr {
          min-height: 187px;

          td {
            flex: none;
            border: 1px solid #cccccc;
            position: relative;
            max-height: 178px;
          }
        }
      }

      td:first-child {
        // border-left: none; /* 移除第一列的左边框 */
      }

      th::after {
        position: absolute !important;
        content: "";
        background: #f1f7ff;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: auto;
        border: 1px solid #cccccc;
        border-bottom: none !important;
        border-right: none;
      }

      th:nth-child(1)::after {
        border-radius: 8px 0 0 0;
      }

      th:last-child::after {
        border-radius: 0 8px 0 0;
        width: auto !important;
        border-right: 1px solid #cccccc !important;
      }

      tr {
        position: relative;

        overflow: hidden;
      }

      tr::after {
        position: absolute;
        content: "";
        // clear: both;
        left: 0;
        top: 0;
        right: -1px;
        bottom: -1px;
        border: 3px solid transparent;
        z-index: -1;
      }

      td::after {
        position: absolute;
        content: "";
        left: 0;
        top: 0;
        right: 0px;
        bottom: 0px;
        border: 1px solid transparent;
        z-index: 2;
        pointer-events: none;
      }

      td {
        min-width: 244px;
        border-right: none !important;
        background-color: white;
        height: 187px;
        vertical-align: top;
      }

      td:last-child {
        border-right: 1px solid #cccccc !important;
        /* 移除最后一列的右边框 */
      }
    }
  }

  position: relative;

  .content-right {
    display: flex;
  }
}

.creation-modal {
  .modal-header {
    height: 57px;
    background: linear-gradient(135deg,
        rgba(65, 122, 255, 0.3) 0%,
        rgba(171, 80, 255, 0.084) 100%);

    .moadl-title {
      justify-content: space-between;
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 22px;

      &>div:first-child {
        display: flex;
        align-items: center;

        &>span {
          font-weight: 500;
          font-size: 16px;
          color: #1d2129;
          margin-left: 17px;
        }
      }

      &>div:last-child {
        cursor: pointer;
      }
    }
  }
}

.modal-content {
  padding: 18px 25px;
  padding-bottom: 0;

  .modal-download {
    padding: 0 10px;
    height: 47px;
    background: #f6f7fb;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 19px;

    &>a:last-child {
      display: flex;
      text-decoration: dashed;
      align-items: center;
      color: #165dff;
      cursor: pointer;

      &>span {
        margin-left: 8px;
      }
    }
  }
}

.arco-dropdown-menu {
  max-height: 100%;
}

.transparent-input {
  .arco-textarea {
    padding: 0;
    background-color: transparent;
    resize: none;
    border: none !important;
    position: static !important;
  }

  .textarea-word-limit {
    position: absolute;
    font-size: 12px;
    bottom: 6px;
    right: 10px;
    color: rgb(134, 144, 156);
    -webkit-user-select: none;
    user-select: none;
  }

  .arco-textarea-wrapper {
    position: static !important;
  }

  .arco-textarea:focus {
    border: none !important;
  }

  .arco-textarea-word-limit {
    bottom: 5px;
    right: 5px;
  }

  .textarea-box {
    li {
      position: absolute;
      left: 8px;
      top: -1px;
    }
  }
}

.td-box {
  height: 100%;
  max-height: 155px;
  padding: 15px 14px;
  box-sizing: border-box;
  overflow: auto;

  .storyboardName-text {
    font-weight: 600;
    font-size: 14px;
    color: #165dff;
    margin-bottom: 9px;
  }

  .smart-b-icon {
    position: absolute;
    bottom: 5px;
    left: 5px;
    cursor: pointer;
    z-index: 2;
  }
}

.trpo {
  .drpo {
    position: fixed;
    top: 0;
  }
}

.selected-row {
  position: relative;

  // border: 1px solid #165dff !important;
  // border-top: none !important;
  td {
    // border-top: 1px solid #165dff !important;
    border-bottom: none;
    // background-color: #f8fafd !important;
    color: #165dff !important;
  }
}

.drag-position {
  position: relative;

  .drag-handle {
    width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute !important;
    color: #555;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    left: 9px;
  }
}

.tr-active::after {
  border: 3px solid #165dff !important;
  z-index: 1 !important;
  pointer-events: none;
  margin-left: -1px;
  margin-top: -1px;
}

.tr-hover::after {
  border: 2px solid #165dff !important;
  z-index: 1 !important;
  margin-right: 1px;
  pointer-events: none;
}

.hidden {
  display: none;
}

// 其余的CSS保持不变
.flexbox {
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  /* 或者其他你想要的宽度 */
}

.flex-item {
  flex: 1 0 auto;
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}



.col-group {
  display: flex;
  flex-direction: row;
  height: 100vh;

  @media (max-width: 540px) {
    flex-direction: column;
  }
}

/* Column-half */
body {
  overflow: hidden;
}

.column-half {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;

  &:last-child {
    border-left: 1px solid var(--gray-3);

    @media (max-width: 540px) {
      border-left: none;
      border-top: 1px solid var(--gray-3);
    }
  }

  &>.main-group {
    flex-grow: 1;
  }
}

/* Collaboration status */
.collab-status-group {
  align-items: center;
  background-color: var(--white);
  border-top: 1px solid var(--gray-3);
  bottom: 0;
  color: var(--gray-5);
  display: flex;
  flex-direction: row;
  font-size: 0.75rem;
  font-weight: 400;
  gap: 1rem;
  justify-content: space-between;
  padding: 0.375rem 0.5rem 0.375rem 1rem;
  position: sticky;
  width: 100%;
  z-index: 100;

  button {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    align-self: stretch;
    background: none;
    display: -webkit-box;
    flex-shrink: 1;
    font-size: 0.75rem;
    max-width: 100%;
    padding: 0.25rem 0.375rem;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    &::before {
      background-color: var(--color);
      border-radius: 0.375rem;
      content: "";
      height: 100%;
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
      width: 100%;
      z-index: -1;
    }

    &:hover::before {
      opacity: 1;
    }
  }

  label {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    gap: 0.375rem;
    line-height: 1.1;

    &::before {
      border-radius: 50%;
      content: " ";
      height: 0.35rem;
      width: 0.35rem;
    }
  }

  &[data-state="online"] {
    label {
      &::before {
        background-color: var(--green);
      }
    }
  }

  &[data-state="offline"] {
    label {
      &::before {
        background-color: var(--red);
      }
    }
  }
}

.tiptap {
  :first-child {
    margin-top: 0;
  }

  /* Placeholder (at the top) */
  p.is-editor-empty:first-child::before {
    color: var(--gray-4);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  p {
    word-break: break-all;
  }

  /* Give a remote user a caret */
  .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  /* Render the username above the caret */
  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -1.4em;
    user-select: none;
    white-space: nowrap;
  }
}

.creationScript-table::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.creationScript-table::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.creationScript-table::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 30px;
}

// .creationScript-table-container::-webkit-scrollbar {
//   display: none;
// }
.table-anchor {
  position: absolute;
  right: 0;
  top: 20%;
  z-index: 99;
}

@keyframes blinkBorder {
  0% {
    border-color: transparent;
  }

  /* 50% { border-color: rgb(255, 205, 54); }   */
  50% {
    border-color: red;
  }

  100% {
    border-color: transparent;
  }
}

.blinking-border::after {
  animation: blinkBorder 1s infinite !important;
}

.avatar-script {
  width: 100%;
  height: 100%;

  img {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }


}

.delete-caption {
  cursor: pointer;
}

// tr {
//   &>td:first-child {
//     &>div:first-child {
//       padding-left: 35px !important;
//     }
//   }
// }
