figure.card-wrapper {
  overflow: hidden;
  width: 180px;
  height: 230px;
  border-radius: 6px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f8ff;
  position: relative;
  margin-right: 13px;
  box-sizing: border-box;

  &:hover .trigger-wrapper {
    bottom: 0px !important;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    /* 确保图片保持比例，不拉伸 */
  }

  &.opeater-box {
    &:hover .checkbox-wrapper {
      display: block;
    }
  }

  .show {
    display: inline-block !important;
  }

  .checkbox-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
  }

  img {
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px 6px 8px 8px;

    &.mp3 {
      width: 60%;
      height: 54%;
      text-align: center;
    }
  }

  .type-wrapper {
    position: absolute;
    top: 3px;
    right: 3px;
    z-index: 10;
    transform: scale(0.7);
    // background: #ffffff;
    // border-radius: 2px;
    // border: 1px solid #165dff;
    // .arco-tag-checked.arco-tag-blue {
    //   background: #ffffff;
    // }
    // .arco-tag-checked {
    //   background: #ffffff;
    // }
  }

  .time-wrapper {
    position: absolute;
    left: 12px;
    bottom: 12px;
    z-index: 10;
    width: 35px;
    line-height: 16px;
    font-size: 10px;
    background: rgba(51, 51, 51, 0.44);
    border-radius: 2px 2px 2px 2px;
    font-weight: 500;
    font-size: 10px;
    color: #ffffff;
    text-align: center;
  }

  .life-wrapper {
    z-index: 10;
    width: 30px;
    background: #707070;
    border-radius: 2px 2px 2px 2px;
    font-family:
      PingFang SC,
      PingFang SC;
    font-weight: 500;
    font-size: 10px;
    color: #ffffff;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    position: absolute;
    bottom: 3px;
    right: 3px;
  }

  .trigger-wrapper {
    box-sizing: border-box;
    position: absolute;
    bottom: -64px;
    width: 100%;
    height: 64px;
    background: #f5f8ff;
    border-radius: 6px;
    border: 1px solid #e6e9ed;
    display: flex;
    padding: 10px 17px;
    z-index: 10;

    // transition: 0.5s;
    &.active {
      bottom: 0px;
    }

    .more-icon {
      position: relative;
      top: 13px;
      left: 5px;
      cursor: pointer;
    }

    .msg {
      width: 120px;
      position: relative;

      .name {
        font-weight: 500;
        font-size: 16px;
        color: #333333;
      }

      .time {
        color: #999999;
        font-size: 12px;
        transform: scale(0.8);
        position: absolute;
        width: 150px;
        left: -14px;
      }
    }
  }
}

.temp-img {
  width: 100%;
  height: 100%;
  background: #F5F8FF;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 88px;
    height: 88px;
  }
}
