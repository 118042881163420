.script-item-wrapper {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #eeeeee;
  padding: 14px 23px 16px 22px;
  position: relative;
  &.active {
    background: #f6faff;
    border-color: #165dff;
  }
  &:hover {
    background: #f6faff;
    border-color: #165dff;
    .name-icon {
      display: none !important;
    }
    .action-bar {
      display: inline-block;
    }
    .checkbox {
      display: flex;
      align-items: center;
    }
  }
  &.disabled-select:hover {
    .name-icon {
      display: inline-block !important;
    }
  }
  .checkbox {
    display: none;
    margin-right: 3px;
    position: relative;
    left: -5px;
  }
  .action-bar {
    position: absolute;
    top: 17px;
    right: 25px;
    display: none;
    .action-icon {
      margin-right: 16px;
      cursor: pointer;
      &:hover {
        color: #165dff;
      }
    }
  }
  .name {
    display: flex;
    align-items: center;
    & > span.name-icon {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #165dff;
      border-radius: 50%;
      margin-right: 12px;
    }
    font-size: 16px;
    color: #333333;
  }
  .title {
    margin-top: 8px;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
  }
  .context {
    font-weight: 400;
    font-size: 14px;
    color: #9e9e9e;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .msg-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    .user-time {
      display: flex;
      align-items: center;
    }
    .time {
      font-size: 12px;
      color: #999999;
      display: flex;
      // margin-left: 10px;
    }
    .count {
      font-size: 12px;
      color: #999999;
    }
    .img-wrapper {
      position: relative;
      img:nth-child(1) {
        position: absolute;
        z-index: 11;
      }
      img:nth-child(2) {
        position: absolute;
        left: 20px;
        z-index: 10;
      }

      img:nth-child(3) {
        position: absolute;
        left: 40px;
        z-index: 9;
      }
      img:nth-child(4) {
        position: absolute;
        left: 60px;
        z-index: 8;
      }
      img:nth-child(5) {
        position: absolute;
        left: 80px;
        z-index: 7;
      }
    }
  }
}
.script-item-wrapper {
  width: 100%;
  margin-right: 20px;
}

