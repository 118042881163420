.user-default {
  font-family:
    PingFang SC,
    PingFang SC;
  width: 19px;
  height: 19px;
  cursor: pointer;
  line-height: 19px;
  text-align: center;
  border-radius: 50%;
  color: white;
  font-weight: 500;
  font-size: 8px;
}

