.videoanalysis {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  min-width: 1270px;
  .video_headerimg {
    min-height: 120px;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .script-content {
    background: hsl(0, 0%, 100%);
    flex: 1;
    border-radius: 18px 18px 0px 0px;
    position: relative;
    top: -26px;
    margin-bottom: -26px;
    display: flex;
    flex-direction: column;
    .search {
      position: absolute;
      display: flex;
      align-items: center;
      border: 1px solid transparent;
      background: #ffffff;
      left: 50%;
      top: calc(-58px / 2);
      transform: translateX(-50%);
      width: 918px;
      height: 58px;
      border-image: linear-gradient(to right, white, white),
        linear-gradient(133deg, #165dff, #be7bff);
      border-radius: 50px;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(to right, white, white),
        linear-gradient(133deg, #165dff, #be7bff);
      box-sizing: border-box;
      .arco-input-group-wrapper {
        height: 100%;
      }

      .arco-input-group-wrapper .arco-input-inner-wrapper {
        border: none;
        background: transparent;
        border-radius: 50px;
        font-size: 14px;
      }
    }

    .search-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      background: linear-gradient(130deg, #165dff 0%, #c497fb 100%);
      border-radius: 5030px;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      margin-right: 8px;
      border: none;
      &:hover {
        color: #ffffff;
      }
    }

    .card-root {
      margin-top: 28px;
      padding-left: 36px;
      display: flex;
      align-items: center;
    }

    .video-content {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      left: 50%;
      top: calc(58px / 2);
      transform: translateX(-50%);
      width: 918px;
      height: 44px;
      font-weight: 400;
      font-size: 14px;
      color: #999999;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      .video-titleimg {
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          margin-left: 5px;
        }
      }
    }
    .video-msg {
      margin-top: auto;
      text-align: center;
      font-family:
        PingFang SC,
        PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #999999;
      margin-bottom: 20px;
    }
  }

  .video-content {
    p:nth-child(1) {
      margin-left: 34px;
    }

    p:nth-child(2) {
      margin-right: 18px;
    }
  }

  .lgparsing {
    width: calc(100% - 34px - 34px);
    margin: 110px 34px 0 34px;

    .lgparsing-header {
      margin-bottom: 8px;
    }

    .lgparsing-contel {
      display: flex;
      align-items: center;
      // max-width: 1800px;
      // min-width: 1400px;
      flex-wrap: wrap;
      .lgparsing-contel_updatabutton {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .updad_but {
        width: 118px;
        height: 32px;
        background: #ffffff !important;
        border-radius: 4px 4px 4px 4px;
        font-weight: 400;
        font-size: 14px;
        color: #165dff;
        line-height: 22px;
        margin-top: 14px;
        img {
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }
      }
      .arco-btn-size-default {
        padding: 0;
      }
    }
    .lgparsing_contel_updata {
      width: 180px;
      height: 230px;
      background: #f5f8ff;
      border-radius: 4px 4px 4px 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // margin-bottom: 15px;
      p {
        font-size: 12px;
        color: #999999;
        &:nth-child(2) {
          font-weight: 600;
          font-size: 16px;
          color: #666666;
          line-height: 22px;
          margin-top: 5px;
        }
        &:nth-child(3) {
          margin-top: 9px;
          font-size: 12px;
          color: #999999;
        }
        &:nth-child(4) {
          font-size: 12px;
          color: #999999;
        }
      }
    }
    .lgparsing-contel-bodydiv {
      width: 180px;
      height: 285px;
      background: #f5f8ff;
      border-radius: 4px 4px 4px 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      p {
        font-size: 12px;
        color: #999999;
        &:nth-child(2) {
          font-weight: 600;
          font-size: 16px;
          color: #666666;
          line-height: 22px;
          margin-top: 5px;
        }
        &:nth-child(3) {
          margin-top: 9px;
          font-size: 12px;
          color: #999999;
        }
        &:nth-child(4) {
          font-size: 12px;
          color: #999999;
        }
      }
    }

    .lgparsing-contel-bodyactive {
      width: 180px;
      height: 285px;
      background: #e8ebf3;
      border-radius: 4px 4px 4px 4px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
    }

    .lgparsing-contel-analysis {
      flex: 1 1;
      // margin-bottom: 15px;
    }
  }

  .video-agdetil {
    width: 180px;
    height: 230px;
    border-radius: 8px 8px 8px 8px;
    // margin-right: 23px;
    // border: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .video-agdetil-headerimg {
      position: relative;
      height: 100%;

      .headerim-contelimg {
        width: 100%;
        // height: 214px;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        // background-color: red;
        // object-fit: none
      }

      .headerim-contetext {
        top: 9px;
        right: 8px;
        position: absolute;
        width: 26px;
        height: 16px;
        background: #ffffff;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #165dff;
        font-weight: 400;
        font-size: 10px;
        color: #165dff;
        line-height: 16px;
        text-align: center;
        font-style: normal;
        text-transform: none;
      }
      .headerim-conTime {
        width: 35px;
        height: 14px;
        position: absolute;
        left: 9px;
        bottom: 10px;
        background: rgba(51, 51, 51, 0.44);
        border-radius: 2px 2px 2px 2px;
        font-weight: 500;
        font-size: 10px;
        color: #ffffff;
        line-height: 14px;
        text-align: center;
      }
      .footer_modal_title {
        position: absolute;
        width: calc(100% - 28px);
        height: 50px;
        bottom: 0;

        background-color: #f5f8ff;
        border-radius: 4px 4px 0 0;
        padding: 11px 14px 4px 14px;
        transition: transform 0.5s ease-in-out;
        transform: translateY(100%);
        .title_text {
          width: 100%;
          font-weight: 600;
          // font-weight: bold;
          font-size: 16px;
          color: #333333;
          // margin-top: 5px;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          -webkit-line-clamp: 1;
        }
        .title_time {
          font-weight: 400;
          font-size: 10px;
          color: #999999;
          line-height: 22px;
          text-align: left;
        }
      }
      .footer_modal_title.slide-in {
        transform: translateY(0);
      }
      .footer_modal_title.slide-out {
        transform: translateY(100%);
      }
      .jeaderime-context-delete {
        z-index: 2;
        display: flex;
        top: 4px;
        left: 4px;
        position: absolute;
        width: 22px;
        height: 22px;
        background: rgba(51, 51, 51, 0.51);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 14px;
          height: 14px;
        }
      }
      .headerim-contel-Overlay {
        width: 100%;
        height: 100%;
        // background-color: red;
        // background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;

        position: absolute;
        color: #ffffff;
        background-color: rgba(50, 50, 50, 0.58);

        .contel {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .containerto {
            display: inline-block; /* 或者使用其他合适的display值 */
            // border: 5px solid #fff; /* 边框样式 */
            border-radius: 50%; /* 圆形边框 */
            // overflow: hidden; /* 确保SVG不会超出容器 */
            width: 52px; /* 根据需要调整大小 */
            height: 52px; /* 根据需要调整大小 */
            .arco-progress-circle-text {
              display: none;
            }
          }
          img {
            width: 52px;
            height: 52px;
          }
          p {
            margin-top: 8px;
          }
        }

        .contel-error {
          width: 140px;
          display: flex;
          align-items: center;
          justify-items: center;
          height: 20px;
          width: 165px;
          display: flex;
          align-items: center;
          justify-items: center;
          margin-left: 89px;
          img {
            margin-left: 6px;
            width: 14px;
            height: 14px;
          }
          .error-span {
            display: inline-block;
            width: 36px;
            height: 20px;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid #ffffff;
            font-weight: 400;
            font-size: 12px;
            text-align: center;
            color: #ffffff;
            line-height: 20px;
            margin-left: 9px;
          }
        }
      }
    }

    .video-agdetil-context {
      .context-titile {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 2;
        font-weight: 500;
        font-size: 14px;
        color: #333333;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin: 7px 7px 0 12px;
      }

      .context-laber {
        margin-top: 6px;
        display: flex;
        // justify-content: center;
        align-items: center;

        span {
          // display: inline-block;
          text-align: center;
          width: 48px;
          height: 24px;
          background: linear-gradient(
            128deg,
            rgba(22, 93, 255, 0.2) 0%,
            rgba(202, 152, 250, 0.2) 100%
          );
          border-radius: 16px 16px 16px 16px;
          font-weight: 400;
          font-size: 12px;
          color: #165dff;
          line-height: 22px;
          // text-align: left;
          font-style: normal;
          text-transform: none;
          margin-left: 8px;
        }
      }
    }
  }
}
.videoallyis_detil_header {
  min-width: 1196px;
}
//modal 弹窗
.lgparsing-modal-body1 {
  width: 769px;
  height: 80vh;

  background: #ffffff;
  padding: 0 0 20px 0;
  border-radius: 8px 8px 8px 8px;
  .hover_contel_lapoing {
    height: calc(80vh - 20px);
    overflow-y: scroll;
  }
  .arco-modal-content {
    padding: 0 0 0 35px;
  }

  .arco-modal-footer {
    display: none;
  }

  .modal-hadertitle {
    font-weight: 500;
    font-size: 18px;
    color: #1d2129;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    padding: 23px 0 23px 0;
  }

  .modal-bodycontel {
    // margin: 2 0 0 0;

    p {
      margin: 13px 0;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
}
.lgparsing-modal-body1::-webkit-scrollbar {
  display: none;
}
//footer 按钮select  全局
.Analysis-Details-menu-flex {
  height: 139px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  // justify-content: space-around;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 108px;
    height: 28px;
    margin-top: 13px;

    img {
      margin-right: 3px;
    }
  }

  .Analysis-Details-menu-flex-item {
    background: linear-gradient(
      135deg,
      rgba(22, 93, 255, 0.15) 0%,
      rgba(203, 152, 250, 0.15) 100%
    );
    border-radius: 4px 4px 4px 4px;
  }
}

.Analysis-Details-Title-Tooltip {
  margin-left: 53px;

  .arco-tooltip-content {
    border-radius: 4px;
  }

  .arco-trigger-arrow {
    left: 50px !important;
  }
}

.Analysis-Details {
  display: flex;
  width: 100%;
  // flex: 1;
  height: 100%;
  min-width: 1196px;
}

.Analysis-Details-Left {
  margin-top: 18px;
  width: 331px;
  overflow: auto;
  background: #ffffff;
  border-radius: 18px 18px 0px 0px;
  padding-left: 27px;
  // box-shadow: 0px 1px 6px 0px rgba(129, 145, 167, 0.35);

  .Left-header-Title {
    margin: 19px 0 0 0;
    p {
      display: flex;

      span:nth-child(1) {
        .arco-icon {
          width: 22px;
          height: 22px;
          margin-left: -4px;
        }
      }

      span:nth-child(2) {
        margin-left: 12px;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }

  .Left-contel-Video {
    width: calc(100% - 24px);
    padding-right: 24px;
  }

  .Left-footel-Script {
    display: flex;
    flex-direction: column;

    .script-Property {
      margin-top: 14px;
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        flex-wrap: wrap;
        margin-top: 8px;

        p {
          border-radius: 16px 16px 16px 16px;
          font-weight: 400;
          font-size: 12px;
          color: #333333;
          line-height: 22px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          padding: 3px 12px;
          margin-right: 14px;

          &:nth-child(1) {
            background: #e2ecff;
          }

          &:nth-child(2) {
            background: #fef2e9;
          }

          &:nth-child(3) {
            background: #ebf5ed;
          }

          &:nth-child(4) {
            background: #f3e6ff;
          }
        }
      }
    }
  }
  .Left-contel-Video-TitleM {
    font-size: 18px;
    font-weight: 600;
    margin-top: 14px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    padding-right: 9px;
    align-items: center;
    span:nth-child(1) {
      text-align: center;
      width: 180px;
      font-size: 18px;
      font-weight: 600;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    // span:nth-child(2) {
    //   text-align: center;
    //   font-size: 14px;
    //   line-height: 14px;
    // }
  }
  .Left-contel-Video-detils {
    width: 274px;
    // height: 80px;
    padding: 12px;
    background: #fcfdff;
    border-radius: 6px 6px 6px 6px;
    border: 1px solid #dddddd;
    .header-contel {
      display: flex;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 22px;
      text-align: left;
      // height: 50%;
      .hader_title {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 18px;
        flex: 1;
        // -webkit-line-clamp: 2;
        // white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // text-align: center;
        // width: 100px; /* 设置文本容器宽度 */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 设置最大行数为 2 */
        -webkit-box-orient: vertical;
      }
    }
    .footel_title {
      margin-top: 12px;
      font-weight: 400;
      font-size: 14px;
      color: #666666;
      line-height: 22px;
      text-align: left;
      span {
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 18px;
      }
    }
  }
  .script-Property {
    p:nth-child(1) {
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.Analysis-Details-Right {
  overflow: auto;
  margin-left: 24px;
  margin-top: 18px;
  flex: 1;
  background: #ffffff;
  border-radius: 18px 18px 0px 0px;
  display: flex;
  flex-direction: column;
  // box-shadow: 0px 1px 6px 0px rgba(129, 145, 167, 0.35);
  justify-content: space-between;
  .Right-body-top {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 31px;

    .Right-header-Title {
      margin-top: 19px;
      display: flex;
      align-items: center;

      span:nth-child(1) {
        margin-right: 7px;
        font-weight: 500;
        font-size: 18px;
        color: #333333;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }
    }
  }

  .Right-body-contel {
    margin-top: 13px;
    display: flex;
    flex-direction: column;
    flex: 1;
    .Right-body-table {
      flex: 1;
      margin-right: 20px;
      border-spacing: 0;
      empty-cells: show;
      // width: 1203px;
      width: calc(100% - 20px);
      thead {
        height: 48px;
        background: #f1f7ff;
        th {
          border: 1px dashed #cccccc;
          border-bottom: none;
          border-right: none;
          box-sizing: border-box;

          p {
            // width: 30px;
            font-weight: 600;
            font-size: 18px;
            color: #333333;
            line-height: 22px;
            text-align: left;
            font-style: normal;
            text-transform: none;
            padding: 0 0 0 32px;
            border-right: none;
          }

          &:nth-child(1) {
            width: 12%;
            border-radius: 8px 0px 0px 0px;
            p {
              padding: 0 0 0 15px;
            }
          }

          &:nth-child(2) {
            width: 25%;
          }

          &:nth-child(3) {
            width: 50%;
          }

          &:nth-last-child(1) {
            width: 13px;
            border-radius: 0px 8px 0px 0px;
            border-right: 1px dashed #cccccc;
          }
        }

        // :nth-child(4){
        //   border-radius: 0px 8px 0px 0px;
        //   border-right: 1px solid #cccccc;
        // }
      }

      tbody {
        tr {
          position: relative;
          // height: 80px;
          // height: 25%;
          max-height: 112px;
        }

        .tr-activeto::after {
          pointer-events: none;
          position: absolute;
          z-index: 1;
          top: -1px;
          content: "";
          bottom: 0;
          right: 0;
          left: 0;
          border: 1px solid #165dff;
        }
        .tr-active-foter::after {
          pointer-events: none;
          position: absolute;
          z-index: 1;
          top: -1px;
          content: "";
          bottom: 0;
          right: 0;
          left: 0;
          border: 1px solid #165dff;
          border-radius: 0 0 8px 8px;
        }

        tr > td {
          // text-align: center;
          border: 1px dashed #cccccc;
          border-right: none;
          border-top: none;
        }

        tr {
          td {
            &:nth-child(1) {
              width: 12%;
            }

            &:nth-child(2) {
              width: 25%;
            }

            &:nth-child(3) {
              width: 50%;
            }

            &:nth-last-child(1) {
              width: 13%;
            }

            // width: 100%;
            // height: 110px;
            // height: 80px;
            height: 96px;
            max-height: 110px;
            .createandeil-voide-datatd {
              height: calc(100% - 32px);
              width: calc(100% - 64px);
              overflow: auto;
              margin: 16px 32px;
              word-wrap: break-word;
              font-weight: 400;
              font-size: 14px;
              color: #3d3d3d;
              line-height: 20px;
              text-align: left;
              font-style: normal;
              text-transform: none;
              position: relative;
            }
            .createandeil-voide-datatd::-webkit-scrollbar {
              // height: 101px;
              // background-color: red;
              display: none;
            }
            .createandeil-voide-datatd {
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none; /* IE and Edge */
            }
          }
        }

        .td-p-title {
          div {
            width: 100%;
            margin: 0;
            // overflow:;
          }

          p:nth-child(1) {
            margin: 0 0 0 15px;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }

          p:nth-child(2) {
            margin: 8px 0 0 15px;
            font-weight: 400;
            font-size: 14px;
            color: #333333;
            line-height: 20px;
            text-align: left;
            font-style: normal;
            text-transform: none;
          }
        }

        td:nth-last-child(1) {
          border-right: 1px dashed #cccccc;
        }

        tr:nth-last-child(1) {
          td {
            border-bottom: 1px dashed #cccccc;
          }

          td:nth-child(1) {
            border-radius: 0px 0px 0px 8px;
          }

          td:nth-last-child(1) {
            border-radius: 0px 0px 8px 0px;
            border-right: 1px dashed #cccccc;
          }
        }
      }
    }
  }

  // .hover-body-contel::-webkit-scrollbar {
  //   display: none;
  // }

  .Right-body-contel::-webkit-scrollbar {
    width: 5px;
  }

  .Right-body-contel::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .Right-body-contel::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 30px;
  }
  .hover-body-contel::-webkit-scrollbar-thumb {
    background: transparent;
  }
  .Right-header-Title-w {
    // width: 1203px;
    margin-top: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span:nth-child(1) {
      margin-right: 7px;
      font-weight: 500;
      font-size: 18px;
      color: #333333;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .title-button {
      height: 30px;
      background: linear-gradient(
        135deg,
        rgba(22, 93, 255, 0.16) 0%,
        rgba(203, 152, 250, 0.16) 100%
      );
      border-radius: 2px 2px 2px 2px;

      span {
        font-weight: 400;
        font-size: 13px;
        color: #165dff;
        line-height: 22px;
        text-align: center;
        font-style: normal;
        text-transform: none;
        margin-right: 0px;
      }
    }
  }

  .Right-body-contel-footel {
    margin-top: 8px;
    margin-right: 16px;
    height: 151px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #eeeeee;
    .footel-content {
      margin: 15px 0px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 125px;

      p {
        padding: 0 15px 0 15px;
        display: flex;
        justify-content: space-between;
        font-weight: 400;
        font-size: 14px;
        color: #333333;
        line-height: 25px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        align-items: center;
        height: 25px;
        border-radius: 0px 0px 0px 0px;
      }
      .opcontewrap {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pactiver {
        background: #e3ebff;
        border-radius: 0px 0px 0px 0px;
      }
    }

    .hover-footel-content::-webkit-scrollbar {
      display: none;
    }
  }

  .Right-body-footel {
    border-top: 1px solid #eeeeee;
    margin: 14px 16px 0 0;
    // height: 100px;
    min-height: 72px;
    background: #ffffff;
    display: flex;
    justify-content: end;
    align-items: center;

    .footer-music {
      width: 117px;
      height: 38px;
      border-radius: 2px 2px 2px 2px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0;
      font-weight: 400;
      font-size: 14px;
      color: #165dff;
      line-height: 22px;
      text-align: center;
      font-style: normal;
      text-transform: none;
    }

    .body-footer-right {
      margin-right: 20px;

      .footer-button-file {
        width: 102px;
        height: 38px;
        background: linear-gradient(135deg, #165dff 0%, #cb98fa 100%);
        border-radius: 2px 2px 2px 2px;
        border: none;
        margin-left: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;

        span {
          margin-left: 3px;
        }

        // text-align: left;
      }
    }
  }
}
.Analysis-Details-Right::-webkit-scrollbar {
  display: none;
}
.video_detils_tooltiptitle {
  width: 300px;
  // display: flex;
}

