.Invitation {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;

    .Invitation_body {
        flex: 1;

        .Invitation_img {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-image: url('../../assets/Invitation/invitationbody.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center;
        }

        // .invbody_main_img{
        //     position: relative;
        // }
        .invbody_main_img {
            width: 475px;
            height: 667px;
            background: #FFFFFF;
            border-radius: 12px 12px 12px 12px;
            border: 1px solid #DDDDDD;
            background-image: url('../../assets//Invitation//invitationmain.png');
            position: relative;
        }

        .invbody_main_imgpoab {
            position: absolute;
            top: 30%;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            flex-direction: column;

            .invbody_name {
                font-weight: 400;
                font-size: 18px;
                color: #333333;
                line-height: 26px;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-bottom: 9px;
            }

            .invbody_depe {
                font-weight: 500;
                font-size: 18px;
                color: #333333;
                line-height: 25px;
                text-align: left;
                font-style: normal;
                text-transform: none;
                margin-bottom: 39px;

                .depe_wegin {
                    font-size: 19px;
                    color: #333333;
                    font-weight: 600;
                }
            }

            .arco-input-inner-wrapper-default {
                width: 380px;
                height: 52px;
                background: #FFFFFF;
                border-radius: 8px 8px 8px 8px;
                border: 1px solid #EEEEEE;
                margin-bottom: 13px;
            }

            .arco-input-group-suffix {
                margin-right: 34px;
            }

            .arco-btn-primary {
                height: 50px;
                background: #165DFF;
                border-radius: 8px 8px 8px 8px;
                margin-bottom: 12px;
            }

            .invbody_time {
                font-weight: 400;
                font-size: 14px;
                color: #999999;
                line-height: 20px;
                text-align: left;
                font-style: normal;
                text-transform: none;
                text-align: center;
                margin-bottom: 152px;
            }

            .invbody_timegoli {
                font-weight: 400;
                font-size: 16px;
                color: #165DFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                text-transform: none;
                text-align: center;
            }
        }

        .inv_contentil {
            width: 100%;
            height: 100%;

            .inv_active_tabconten {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: columns;
                justify-content: center;
                align-items: center;

                .inv_tabbody {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-bottom: 200px;

                    .invbody_pottile {
                        text-align: center;
                        font-weight: 500;
                        font-size: 18px;
                        margin-top: 27px;
                        color: #333333;
                        line-height: 25px;
                        /* text-align: left; */
                        font-style: normal;
                        text-transform: none;
                    }

                    .invbody_title {
                        text-align: center;
                        margin-top: 16px;
                        font-weight: 400;
                        font-size: 14px;
                        color: #666666;
                        line-height: 20px;
                        font-style: normal;
                        text-transform: none;
                    }
                }
            }
        }

    }


}
