.item_img_contel {
  width: 80%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  .banner_image {
    // width: 100%;
    height: 100%;
    max-height: 799px;
    position: relative;
    top: -25px;
  }
  .star_image {
    position: absolute;
    right: 0;
    top: 20px;
  }
  .banner_textfoolt_image {
    //   width: 100%;
    // height: 15%;
    width: 70%;
    max-width: 593px;
    max-height: 45px;
    margin-top: 20px;
  }
}
@media (max-height: 1080px) {
  .responsive-image {
    /* 自适应高度 */
    max-height: none; /* 移除固定高度 */
    height: auto; /* 高度自适应 */
    width: 100%; /* 宽度设置为100%，确保图片不会超出容器 */
  }
}
@media (max-height: 700px) {
  .responsive-image {
    /* 自适应高度 */
    max-height: none; /* 移除固定高度 */
    height: auto; /* 高度自适应 */
    width: 100%; /* 宽度设置为100%，确保图片不会超出容器 */
  }
}
@media (max-height: 1080px) {
  .responsive-image {
    /* 自适应高度 */
    max-height: none; /* 移除固定高度 */
    height: auto; /* 高度自适应 */
    width: 100%; /* 宽度设置为100%，确保图片不会超出容器 */
  }
}

