.project-name {
  font-family:
    Inter,
    -apple-system,
    BlinkMacSystemFont,
    PingFang SC,
    Hiragino Sans GB,
    noto sans,
    Microsoft YaHei,
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif !important;
  // width: 126px !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
}
.editicon-wrapper {
  margin-left: 12px;
}

