.resolution-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -10px;
  li {
    width: 86px;
    height: 68px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #cccccc;
    background: #f8f8f8;
    margin-right: 32px;
    margin-bottom: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &.active {
      background: #3472ff;
      color: white;
      .resolution-box {
        border-color: white;
      }
    }
  }
}

