.modal-excel {
  width: 100%;
  height: 363px;
  border-radius: 4px;
  position: relative;
  border: 1px solid #eeeeee;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 14px;
  align-items: center;
  .arco-upload-trigger-drag {
    padding: 140px 0;
    border: 1px solid #eeeeee;
    background-color: none;
  }
  .excel-icon {
    margin-top: 79px;
  }
  .excel-lable {
    margin-top: 15px;
    color: #1d2129;
    & > span {
      color: #165dff;
    }
  }
  & > p:last-child {
    margin-top: 5px;
    color: #1d2129;
  }
}
.modal-excel:hover {
  background-color: #ececec;
}
.modal-excel-dragover {
  background-color: #ececec;
}

