.resolution-box {
  border: 1px solid #666666;
  &.size9-16 {
    width: 16px;
    height: 28px;
  }

  &.size3-4 {
    width: 20px;
    height: 27px;
  }
  &.size1-1 {
    width: 20px;
    height: 20px;
  }
  &.size4-3 {
    width: 34px;
    height: 25px;
  }
  &.size16-9 {
    width: 34px;
    height: 25px;
  }
  &.size2-1 {
    width: 34px;
    height: 17px;
  }
}

