.checkbox-wrapper {
  font-weight: 400;
}
span.tip {
  margin-left: 22px;
  font-size: 14px;
  color: #333333;
}

span.num-tip {
  margin-left: 4px;
  font-size: 14px;
  color: #165dff;
}

